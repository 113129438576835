<template>
  <div class="grid">
    <Toast />
    <div class="col-12">
      <div class="card">
        <div class="grid-container-desktop" v-if="!loading">
          <div
            class="grid-item"
            v-for="moduleAccess of fullModuleAccesses"
            :key="moduleAccess.moduleName"
          >
            <ModuleSettings
              :key="moduleAccess.moduleName"
              :module="moduleAccess"
              :modelValue="moduleAccess.access"
              @update:modelValue="handleUpdateModelValue"
            ></ModuleSettings>
          </div>
        </div>
        <template v-else>
          <div class="text-center">
            <ProgressSpinner />
          </div>
        </template>
        <div class="row">
          <div class="col-12">
            <Button
              label="Modifier"
              class="button-custom button-info p-2"
              @click="updateModuleAccesses()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ModuleAccessService from "../../service/ModuleAccessService";
import ModuleSettings from "@components/ModuleSettings.vue";

export default {
  components: { ModuleSettings },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      loading: true,
      moduleAccesses: [],
      fullModuleAccesses: [
        {
          moduleName: "prices",
          moduleLabel: "Prix",
          moduleText:
            "- Accès à l'onglet prix pour configurer le tarif des spectacles<br/>- Possibilité de télécharger les factures des reservations",
          moduleIcon: "fas fa-euro-sign",
        },
        {
          moduleName: "commentaries",
          moduleLabel: "Commentaires",
          moduleText:
            "- Possibilité pour les utilisateurs de commenter les spectacles passés",
          moduleIcon: "fas fa-comments",
        },
        {
          moduleName: "reservations",
          moduleLabel: "Réservations",
          moduleText: "- Possibilité pour une école de réserver un spectacle",
          moduleIcon: "fas fa-ticket-alt",
        },
        {
          moduleName: "passed_spectacles",
          moduleLabel: "Spectacles passés",
          moduleText:
            "- Affichage d'un onglet 'spectacles passés' sur la page publique",
          moduleIcon: "fas fa-calendar-check",
        },
        {
          moduleName: "partners",
          moduleLabel: "Partenaires",
          moduleText:
            "- Affichage d'un onglet 'partenaires' sur la page publique<br/>- Ajout d'un menu dans la partie administrateur pour éditer le contenu de la page",
          moduleIcon: "fas fa-address-book",
        },
        //contact
        {
          moduleName: "contact",
          moduleLabel: "Contact",
          moduleText:
            "- Affichage d'un onglet 'contact' sur la page publique permettant aux utilisateurs d'envoyer un mail à l'adresse de contact de l'association",
          moduleIcon: "fas fa-envelope",
        },
      ],
      submitted: false,
    };
  },

  validations() {
    return {};
  },

  moduleAccessService: null,
  created() {
    this.moduleAccessService = new ModuleAccessService();
  },

  mounted() {
    this.moduleAccessService.getAllModuleAccesses().then((data) => {
      this.moduleAccesses = data;
      //fill fullModuleAccesses with moduleAccesses with full data (access,id, moduleAccessId)
      this.fullModuleAccesses = this.fullModuleAccesses.map((module) => {
        const moduleAccess = this.moduleAccesses.find(
          (moduleAccess) => moduleAccess.moduleName === module.moduleName
        );
        if (moduleAccess) {
          module.access = moduleAccess.access;
          module.id = moduleAccess.id;
          module.moduleAccessId = moduleAccess.moduleAccessId;
        }
        return module;
      });
      this.loading = false;
    });
  },
  methods: {
    updateModuleAccesses() {
      this.moduleAccessService
        .updateModuleAccesses(this.fullModuleAccesses)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Terminé",
            detail: "Les modules ont bien été modifiés",
            life: 3000,
          });
          window.location.reload();
        });
    },
    handleUpdateModelValue({ id, access }) {
      const index = this.fullModuleAccesses.findIndex(
        (moduleAccess) => moduleAccess.id === id
      );
      if (index !== -1) {
        this.fullModuleAccesses[index].access = access;
      }
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.grid-item {
  padding: 10px;
}
</style>
