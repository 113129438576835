<script>
import AkLabel from "@components/general/AkLabel";

export default {
  components: { AkLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4",
    },
    validator: {
      type: Object,
      required: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id =
      "id_" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      return false;
    },
    isInvalid() {
      return (
        this.validator !== undefined &&
        this.validator.$invalid &&
        this.alreadySubmitted
      );
    },
    isRequired() {
      return (
        this.validator !== undefined && this.validator.required !== undefined
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
};
</script>

<template>
  <div class="form-check" style="display: flex; align-items: center">
    <Checkbox
      :id="id"
      v-model="currentValue"
      :binary="true"
      class="form-check-input"
      value="{{this.label}}"
      :disabled="this.disabled"
    />
    <ak-label class="ml-2" :id="id" :required="isRequired">{{
      this.label
    }}</ak-label>
    <div>
      <small v-if="isValidatorRequired" class="p-error">
        Ce champ est requis
      </small>
    </div>
  </div>
</template>
