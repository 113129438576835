<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-4">
            <i
              class="mr-3"
              :class="module?.moduleIcon ? module.moduleIcon : 'fas fa-cogs'"
              style="font-size: 2rem"
            ></i
            >{{ module?.moduleLabel }}
          </h4>
          <p class="text-muted mb-0" v-html="module?.moduleText"></p>

          <ak-checkbox
            v-model="currentValue"
            class="p-checkbox-rounded d-block text-align-right"
            :required="true"
            label=""
          ></ak-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AkCheckbox from "@components/input/AkCheckbox";
export default {
  props: {
    module: {
      type: Object,
      required: true,
    },
    modelValue: {},
  },
  components: { AkCheckbox },
  data() {
    return {
      value: null,
    };
  },

  setup() {
    return {};
  },

  created() {},

  methods: {},

  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", { id: this.module.id, access: val });
      },
    },
  },

  mounted() {},
};
</script>
